import { css } from '@emotion/css';
import React from 'react';
import { lightGradient, mq } from '../lib/styles';
import { ReactComponent as WhiteLogo } from '../assets/White.svg';

const ComingSoonPage: React.FC = () => {
	return (
		<div className={styles.container}>
			<WhiteLogo className={styles.logo} />
			<div className={styles.textContainer}>
				<span className={styles.heading}>Pupilar</span>
				<span className={styles.subHeading}>Coming soon</span>
			</div>
		</div>
	);
};

const styles = {
	container: css({
		height: '100vh',
		backgroundImage: `linear-gradient(45deg, ${lightGradient[0]}, ${lightGradient[1]}, ${lightGradient[2]}, ${lightGradient[3]})`,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		[mq[2]]: {
			flexDirection: 'row',
		},
	}),
	logo: css({
		width: '30vw',
		height: '30vh',
		[mq[2]]: {
			height: '30vh',
		},
	}),
	textContainer: css({
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		color: '#FFF',
		margin: '10vh 0',
		[mq[2]]: {
			margin: '0',
			marginRight: '25vh',
			alignItems: 'flex-start',
		},
	}),
	heading: css({
		fontSize: '3rem',
		fontWeight: 'bold',
		[mq[2]]: {
			fontSize: '5rem',
		},
	}),
	subHeading: css({
		fontSize: '1rem',
		marginTop: '1vh',
		fontStyle: 'italic',
		opacity: '0.5',
		[mq[2]]: {
			marginTop: '1.5vh',
			fontSize: '1.5rem',
		},
	}),
};

export default ComingSoonPage;
